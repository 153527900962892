<template>
  <div class="footer">
    <div class="hd">
      <a v-for="(item, index) in links" :key="index" @click="toDetail(item)"
        >{{ item.name }}
      </a>
    </div>
    <div class="bd" >
      <div class="container" style="border-bottom:1px solid #00337d;">
        <div class="clearfix" style="margin-bottom:2%">
          <div class="left">
            <h3>集团网站群</h3>
            <div style="border-top:1px solid #00337d;width:80%"></div>
            <ul class="clearfix">
              <li v-for="(item, index) in companyList" :key="index">
                <a :href="item.pathUrl">{{ item.name }}</a>
              </li>
            </ul>
          </div>
          <div class="right">
            <h3>主办单位：{{ footers.versionInfo }}</h3>
            <div style="border-top:1px solid #00337d"></div>
            <div class="flex">
              <div class="flex_item">
                <ul>
                  <li>服务热线：{{ footers.hotline }}</li>
                  <li>客服电话：{{ footers.telephone }}</li>
                </ul>
              </div>
              <img :src="$baseURL + footers.qrcodeUrl" class="pic" style="margin-top:5%;width: 128px;height: 128px"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ft" v-html="footers.digest"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyList: "",
      links: "",
      footers: "",
      footerStr:''
    };
  },
  async mounted() {
    let res = await this.$api.findCompanyList();
    this.companyList = res.data.data;
    let skip = await this.$api.findSkipList({ type: 1 });
    this.links = skip.data.data;
    let footer = await this.$api.findFooter();
    this.footers = footer.data.data[0];
  },
  methods: {
    toDetail(item) {
      if (item.skipUrl.includes("http")) {
        window.open(item.skipUrl);
      } else {
        this.$router.push(item.skipUrl);
      }
      window.scrollTo(0, 0)
    },
  },
  computed: {},
};
</script>

<style scoped>
</style>
