import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        tabIndex:0,
        showTips:true,
        stations:{},
        columnData:[]
    },
    mutations: {
        setShowTips(state,data){
            state.showTips = data
        },
        setTabIndex(state,data){
            state.tabIndex = data
        },
        setStations(state,data){
            state.stations = data
        },
        setColumnData(state,data){
            state.columnData = data
        }
    },
    actions: {
    },
    getters: {
    },
    modules: {},
    plugins:[
        createPersistedState({
            storage:window.sessionStorage
        })
    ],
});
