<template>
    <div class="container">
      <div id="left" >
        <div class="litem" v-for="(item,index) in leftData" :key="'one'+index" @click="openUrl(item)">
          <img :src="$baseURL + item.imgUrl" alt="" @load="load()" >
        </div>
      </div>
      <div id="right" >
        <div class="ritem" v-for="item in rightData" :key="item.id" @click="openUrl(item)">
          <img :src="$baseURL + item.imgUrl" alt="" @load="load()" >
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "bothFloat",
  props:{
    leftData:{
      type:Array,
      default(){
        return []
      },
    },
    rightData:{
      type:Array,
      default(){
        return []
      }
    }
  },
  methods:{
    load(){
      let leftH = $('#left').height();
      let rightH =$('#right').height();
      const wHeight = document.documentElement.clientHeight;
      $('#left').css("marginTop",-leftH/2);
      $('#right').css("marginTop",-rightH/2);
      $('#left').css("top",wHeight/2);
      $('#right').css("top",wHeight/2);
      $(window).scroll(function () {

        var scrollHeight = document.documentElement.scrollTop;

        setTimeout(()=>{
          $("#left").css({ top: scrollHeight + wHeight/2 + "px" }); //WaitList 是 div ID
          $("#right").css({ top: scrollHeight + wHeight/2 + "px" }); //WaitList 是 div ID
        },50)

      });
    },
    openUrl(item){
      if(item.skipUrl.includes('http')){
        window.open(item.skipUrl);
      }else{
        this.$router.push(item.skipUrl)
      }
    },
  },
}
</script>

<style scoped>
.container{
}
.litem{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.litem img{
  width: 150px;
  height: auto;
  cursor: pointer;
}
.ritem{
  display: flex;
  width: 120px;
}
.ritem img{
  width: 60px;
  height: 480px;
  cursor: pointer;
}
.container #left{
  position:absolute;
  /*margin-top: -240px;*/
  width: 150px;
  z-index: 99;
}
.container #right{
  position:absolute;
  /*margin-top: -240px;*/
  width: 120px;
  z-index: 99;
  right: 0px;
  display: flex;
  top: 0;
}
#left{
  left: 0px;
  top: 0;
}
#right{

}
</style>
