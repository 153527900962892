import { request } from "./request";

/**
 * 统一api管理
 * @param data
 * @returns {AxiosPromise}
 */

export function getTopBanner(data) {
    return request({
        url:"/api/getBanner",
        method: "post",
        data:data
    });
}

export function getNav(data = {}) {
    return request({
        url:"/api/findNewBar",
        method: "post",
        data:data
    });
}

export function getSwiper(data = {}) {
    return request({
        url:"/api/charCarousel",
        method: "post",
        data:data
    });
}

export function getCards(data = {}) {
    return request({
        url:"/api/imgCarousel",
        method: "post",
        data:data
    });
}

export function getColumn(data = {}) {
    return request({
        url:"/api/findColumnData",
        method: "post",
        data:data
    });
}

export function getPartner(data = {}) {
    return request({
        url:"/api/findCooperative",
        method: "post",
        data:data
    });
}

export function getFriendLink(data = {}) {
    return request({
        url:"/api/findBlogrollList",
        method: "post",
        data:data
    });
}

export function getChildColumn(data = {}) {
    return request({
        url:"/api/findChildList",
        method: "post",
        data:data
    });
}

export function getArticleData(data = {}) {
    return request({
        url:"/api/findArticle",
        method: "post",
        data:data
    });
}


export function findData(data = {}) {
    return request({
        url:"/api/findData",
        method: "post",
        data:data
    });
}

export function certificationObject(data = {}) {
    return request({
        url:"/api/certificationObject",
        method: "post",
        data:data
    });
}

export function submitCertification(data = {}) {
    return request({
        url:"/api/submitCertification",
        method: "post",
        data:data
    });
}

export function submitCultivate(data = {}) {
    return request({
        url:"/api/submitCultivate",
        method: "post",
        data:data
    });
}


export function searchColumn(data = {}) {
    return request({
        url:"/api/questionnaireList",
        method: "post",
        data:data
    });
}

export function getQuestion(data={}){
    return request({
        url:"/api/questionDetail",
        method: "post",
        data:data
    });
}

export function submitQuestion(data={}){
    return request({
        url:"/api/questionAnswer",
        method: "post",
        data:data
    });
}


export function findArticleByColumn(data = {}) {
    return request({
        url:"/api/findArticleByColumn",
        method: "post",
        data:data
    });
}


export function create(data = {}) {
    return request({
        url:"/api/suggestAndFeedback/create",
        method: "post",
        data:data
    });
}

export function findSkipList(data = {}) {
    return request({
        url:"/api/findSkipList",
        method: "post",
        data:data
    });
}

export function findCompanyList(data = {}) {
    return request({
        url:"/api/findCompany",
        method: "post",
        data:data
    });
}
export function findFooter(data = {}) {
    return request({
        url:"/api/findFooter",
        method: "post",
        data:data
    });
}

export function searchTitle(data = {}) {
    return request({
        url:"/api/search",
        method: "post",
        data:data
    });
}


// h5相关接口
export function getH5Nav(data = {}) {
    return request({
        url:"/api/getH5Nav",
        method: "post",
        data:data
    });
}


export function getH5ArticleList(data = {}) {
    return request({
        url:"/api/getNavList",
        method: "post",
        data:data
    });
}

export function getChildByColumn(data = {}) {
    return request({
        url:"/api/findChildByColumn",
        method: "post",
        data:data
    });
}

export function getAdvList(data = {}) {
    return request({
        url:"/api/findAllSkip",
        method: "post",
        data:data
    });
}

export function getBulletin(data = {}) {
    return request({
        url:"/api/findBulletin",
        method: "post",
        data:data
    });
}
export function getStationName(data = {}) {
    return request({
        url:"/api/getStationName",
        method: "post",
        data:data
    });
}

export function getIndexColumn(data = {}) {
    return request({
        url:"/api/findIndexColumn",
        method: "post",
        data:data
    });
}



export function getAllColumn(data = {}) {
    return request({
        url:"/api/findAllColumn",
        method: "post",
        data:data
    });
}

export function getArticleById(data = {}) {
    return request({
        url:"/api/findArticleById",
        method: "post",
        data:data
    });
}

export function getArticleListById(data = {}) {
    return request({
        url:"/api/findArticleListById",
        method: "post",
        data:data
    });
}

export function getIndexCharList(data = {}) {
    return request({
        url:"/api/findCharList",
        method: "post",
        data:data
    });
}

export function getIndexImgList(data = {}) {
    return request({
        url:"/api/findImgList",
        method: "post",
        data:data
    });
}

export function getStationInfo(data = {}) {
    return request({
        url:"/api/findStationInfo",
        method: "post",
        data:data
    });
}

export function getBsList(data = {}) {
    return request({
        url:"/api/bsList",
        method: "post",
        data:data
    });
}

export function getIsGray(data = {}) {
    return request({
        url:"/api/isGray",
        method: "post",
        data:data
    });
}

export function getNotice(data = {}) {
    return request({
        url:"/api/findNotice",
        method: "post",
        data:data
    });
}

export function getTopColumnId(data = {}) {
    return request({
        url:"/api/getTopColumnId",
        method: "post",
        data:data
    });
}

export function getCaptcha(data = {}) {
    return request({
        url:"/api/captcha",
        method: "post",
        data:data
    });
}

export function getDataByName(data = {}) {
    return request({
        url:"/api/findDataByName",
        method: "post",
        data:data
    });
}

export function getIndexH5Column(data = {}) {
    return request({
        url:"/api/findH5IndexColumn",
        method: "post",
        data:data
    });
}

export function getCertTypes(data = {}) {
    return request({
        url:"/api/getCertTypes",
        method: "post",
        data:data
    });
}

export function getCertProject(data = {}) {
    return request({
        url:"/api/getCertProject",
        method: "post",
        data:data
    });
}
