import Vue from 'vue'
import App from './App.vue'
import router from '@/router';
import store from '@/store';
import * as api from '../src/network/api'
import {BASE_URL, PIC_URL} from "@/constant";
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '../src/theme/index.css'
Vue.use(ElementUI);

Vue.prototype.$api = api
// Vue.prototype.$baseURL = process.env.VUE_APP_BASE_API
Vue.prototype.$baseURL = PIC_URL
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
