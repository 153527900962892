import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err =>{
        window.location.reload()
    })
}

const routes = [
    {
        path: "/",
        name: "home",
        meta:{
            title:"首页"
        },
        component: () => import("@/pages/home.vue")
    },
    {
	    path: "/h5",
        name: "index",
        meta:{
	        title:"首页",
			keepAlive:false,
	    },
	    component: () => import("@/pages/h5/index.vue"),
		children:[
		],
	},
	{
	    path: "/h5list",
	    name: "h5list",
	    meta:{
	        title:"新闻列表",
			keepAlive:false,
	    },
	    component: () => import("@/pages/h5/list.vue")
	},
    {
        path: "/h5contact",
        name: "h5contact",
        meta:{
            title:"联系我们",
            keepAlive:false,
        },
        component: () => import("@/pages/h5/contact.vue")
    },
	{
	    path: "/h5view",
	    name: "h5view",
	    meta:{
	        title:"新闻详情",
			keepAlive:false,
	    },
	    component: () => import("@/pages/h5/view.vue")
	},
    {
        path: "/generalization",
        name: "generalization",
        meta:{
            title:"动态概括"
        },
        component: () => import("@/pages/generalization.vue"),
    },
    {
        path: "/train",
        name: "train",
        meta:{
            title:"培训学习"
        },
        component: () => import("@/pages/train.vue"),
    },
    {
        path: "/apply",
        name: "apply",
        meta:{
            title:"申请中心"
        },
        component: () => import("@/pages/apply.vue"),
    },
    {
        path: "/management",
        name: "management",
        meta:{
            title:"管理体系"
        },
        component: () => import("@/pages/management.vue"),
    },
    {
        path: "/certificate",
        name: "certificate",
        meta:{
            title:"证书查询"
        },
        component: () => import("@/pages/certificate.vue"),
    },
    {
        path: "/responsibility",
        name: "responsibility",
        meta:{
            title:"社会责任"
        },
        component: () => import("@/pages/responsibility.vue"),
    },
    {
        path: "/publicFile",
        name: "publicFile",
        meta:{
            title:"公开文件"
        },
        component: () => import("@/pages/publicFile.vue"),
    },
    {
        path: "/customer",
        name: "customer",
        meta:{
            title:"客户服务"
        },
        component: () => import("@/pages/customer.vue"),
    },
    {
        path: "/download",
        name: "download",
        meta:{
            title:"下载中心"
        },
        component: () => import("@/pages/download.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        meta:{
            title:"联系我们"
        },
        component: () => import("@/pages/contact.vue"),
    },
    {
        path: "/detail",
        name: "detail",
        component: () => import("@/pages/detail.vue"),
    },
    {
        path: "/list",
        name: "list",
        meta:{
            title:"社会责任"
        },
        component: () => import("@/pages/list.vue"),
    },
    {
        path: "/view",
        name: "view",
        component: () => import("@/pages/view.vue"),
    },
    {
        path: "/question",
        name: "question",
        component: () => import("@/pages/question.vue"),
    },
    //配置页面静态化
    {
        path: "/channels/:pid/:id",
        name: "channels",
        component: () => import("@/pages/channels.vue"),
    },
    {
        path: "/contents/:pid/:id/:articleId",
        name: "contents",
        component: () => import("@/pages/contents.vue"),
    },
    {
        path: "/search",
        name: "search",
        meta:{
            title:"搜索"
        },
        component: () => import("@/pages/search.vue"),
    },
];

const router = new VueRouter({
    mode:'history',
    routes,
    scrollBehavior(to,from,savedPosition){
        if(savedPosition){
            return savedPosition;
        }else{
            return { x : 0, y : 0};
        }
    }
});



router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    $("#left").hide();
    $("#right").hide();
    next()
})

export default router;
