import axios from "axios";

export function request(config) {
    const instance = axios.create({
        baseURL:process.env.VUE_APP_BASE_API,
        timeout: 8000,
        headers:{
            'Content-Type': 'application/json',
        }
    });
    return instance(config);
}
