<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="flex top">
          <div class="flex_item"><a href="/" v-if="logo"><img :src="logo" class="logo"></a></div>
<!--          <div class="dropdown">-->
<!--            <span>站内功能</span>-->
<!--            <ul>-->
<!--              <li><a href="#">站内功能</a></li>-->
<!--              <li><a href="#">站内功能</a></li>-->
<!--            </ul>-->
<!--          </div>-->
          <a class="login" @click="serviceLogin"><img src="/images/home_06.png" width="20">业务登录</a>
          <div class="search">
            <input type="text" placeholder="输入关键字" @blur="serchBlur()" @keyup.enter="toSearch" v-model="searchValue">
            <span @click="clickToSearch"><img src="/images/home_08.png"></span>
          </div>
        </div>
        <div class="nav">
          <ul>
            <li :class="{on:tabIndex == 0}"><div @click="toIndex()">首页</div></li>
            <li :class="{on:(index + 1 )==tabIndex}" v-for="(item,index) in nav" :key="item.id">
              <div @click="navTo(item,item,index + 1,0)">{{item.name}} <i v-if="item.children.length > 0"></i></div>
              <ol :class="{lg:item.children.length > 5}" v-if="item.children.length > 0">
                <li v-for="(val,i) in item.children" :key="val.id" @click="navTo(item,val,index + 1,i)">
                  <div>{{val.name}}</div>
                </li>
              </ol>
            </li>
          </ul>
        </div>
      </div>
    </div>
  <!--  弹窗-->
    <div class="Model_all" id="ma" style="display: none"></div>
    <!--弹出层-->
    <div class="open_modal" id="dl" style="display: none">
      <div class="flaseIcon" @click="closeIcon()"><img src="/images/false.png" /></div>
      <div class="model_cont">
        <div class="model_title">业务平台</div>
        <div class="model_list">
          <div class="model_left">
            <p class="m_big_title">
              <span class="m_dian"></span>
              <span class="m_tip">外部用户登录</span>
            </p>
            <div class="m_listAll" id="wb">
            <!--  展示列表-->
              <div class="list-1">
                <div class="list-item" v-for="item in outers" :key="item.id" >
                  <a :href="item.pathUrl" target="_blank"><span></span> {{item.name}}</a>
                </div>
              </div>
            </div>

          </div>
          <div class="m_line"></div>
          <div class="model_right">
            <p class="m_big_title">
              <span class="m_dian"></span>
              <span class="m_tip">内部员工登录</span>
            </p>
            <div class="m_listAll" id="nb">
              <div class="list-1">
                <div class="list-item" v-for="item in inners" :key="item.id" >
                  <a :href="item.pathUrl" target="_blank"><span></span> {{item.name}}</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  props:{
    logoUrl:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      nav:[],
      searchValue:"",
      blurValue:"",
      inners:[],
      outers:[]
    }
  },
  async mounted() {
    //加载导航数据
    let res = await this.$api.getNav();
    this.nav = res.data.data
    //缓存数据
    this.$store.commit('setColumnData',this.nav);
    this.getBsList()
    // console.log(this.nav)
    //添加首页属性
  },
  methods:{
    serviceLogin(){
      $(".open_modal").slideDown();
      $(".Model_all").slideDown();
    },
    closeIcon(){
      $(".open_modal").slideUp()
      $(".Model_all").slideUp()
    },
    async getBsList(){
      let res = await this.$api.getBsList()
      console.log(res.data.data,'bs===')
      res.data.data.forEach(item=>{
        if(item.type == 1){
          this.inners.push(item)
        }else{
          this.outers.push(item)
        }
      })
    },
    toIndex(){
      this.$store.commit('setTabIndex',0)
      this.$router.push({path:'/'})
    },
    navTo(item,val,index,i){
      console.log(item);
      this.$store.commit('setTabIndex',index)
      this.$router.push(`/channels/${item.id}/${val.id}.html`)

      // if(['社会责任','公开文件','下载中心','政策法规'].includes(val.name)){
      //   this.$router.push({path:'/list',query:{pid:item.id}})
      // }else if(val == item && item.childList.length > 0){
      //   this.$router.push({path:'/'+ item.path.split('.')[0],query:{id:item.childList[0].id,pid:item.id,index:i,name:item.name}})
      // }else{
      //   this.$router.push({path:'/'+ item.path.split('.')[0],query:{id:val.id,pid:item.id,index:i,name:item.name}})
      // }

    },
    serchBlur(){
      this.blurValue = this.searchValue;
      this.searchValue = "";
    },
    toSearch(){
      if(this.searchValue.length == 0){
        this.$message({
          message: '请输入关键字',
          type: 'error'
        });
        return;
      }
      this.$router.push({path:"/search",query:{title:this.searchValue}});
      this.searchValue = "";
      this.blurValue = "";
    },
    clickToSearch(){
      if(this.blurValue.length == 0){
        this.$message({
          message: '请输入关键字',
          type: 'error'
        });
        return;
      }
      this.$router.push({path:"/search",query:{title:this.blurValue}});
      this.blurValue = "";
    }

  },
  computed:{
    ...mapState(['tabIndex']),
    logo(){
      if(this.logoUrl){
        if(this.logoUrl.includes('http')){
          return this.logoUrl
        }
        return this.$baseURL + this.logoUrl;
      }
      return ''
    }
  }
}
</script>

<style scoped>
.header .top .search:hover {
    border: 1px solid #e6ebf5;
    width: 205px;
}

.header .top .search {
    float: left;
    width: 30px;
    border: 1px solid #fff;
    transition: all .3s cubic-bezier(.4,0,.2,1) 0s;
    border-radius: 30px;
    background: #fff;
    overflow: hidden;
}

 .header .top .search:hover input {
    width: 150px;
    padding: 0 10px;
}
.header .top .search input {
    float: left;
    height: 22px;
    line-height: 25px;
    margin: 4px 0 0;
    width: 0;
    padding: 0;
    transition: all .3s cubic-bezier(.4,0,.2,1) 0s;
}
.header .top .search span {
    width: 25px;
    line-height: 30px;
    float: right;
    text-align: center;
    color: #275caa;
}
/*遮罩层*/
body{
  position: relative;
}
.Model_all{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  background: rgba(0,0,0,0.5);
  display: none;
}
/*弹出层*/
.open_modal{
  width: 1200px;
  height: 430px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translateX(-50%);
  background: url(/images/danchuBanner.png) no-repeat;
  background-size: 100% 100%;
  z-index: 99999999999;
  display: none;
}
.flaseIcon{
  display: flex;
  justify-content: flex-end;
}
.model_title{
  margin-left: 438px;
  margin-top: 33px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: 0px;
  color: #00477a;
}
.model_list{
  margin-left: 395px;
  margin-right:149px ;
}
.model_list{
  display: flex;
  margin-top: 68px;
}
p.m_big_title{
  display: flex;
  align-items: center;
}
p.m_big_title span{
  display: block;
}
.m_dian{
  width: 7px;
  height: 7px;
  background-color: #009bff ;
  border-radius: 50%;
  margin-right: 6px;
}
.m_tip{
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: 0px;
  color: #000000;
}
.m_listAll{
  margin-top:27px;
  display: flex;
}
.m_listAll li{
  display: flex;
  align-items: center;
}
.m_listAll li:hover span{
  color: #009bff;
  cursor: pointer;
}
.m_listAll li span{
  display: block;
}
.m_sDian{
  width: 6px;
  height: 6px;
  background-color: #009bff;
  margin-right: 6px;
}
.m_item{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 38px;
  letter-spacing: 0px;
  color: #000000;
}
.m_line{
  width: 0px;
  height: 199px;
  background-color: #ffffff;
  border: solid 1px #dcdcdc;
  margin-top: 8px;
  /*margin-left: 106px;*/
  /*margin-left: 100px;*/
  margin-right: 100px;
}
.list-1{
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}
.list-item{
  width: 160px;
  margin-bottom: 10px;
  font-size: 15px;
  color: #333;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.list-item a{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item span{
  width: 6px;
  height: 6px;
  background-color: #009bff;
  margin-right: 10px;
  display: inline-block;

}
</style>
